// middleware/auth.ts
export default defineNuxtRouteMiddleware(async (to) => {
  if (process.server) return

  const authStore = useAuthStore()
  const publicPages = ['/login', '/register','/students','/student/validate']
  const authRequired = !publicPages.includes(to.path)
  if (to.path.startsWith('/student')) {
    return // permite o acesso direto a estas rotas
  }
  if (!authStore.isAuthenticated && authRequired) {
    return navigateTo('/login')
  }

  if (authStore.isAuthenticated && !authStore.user) {
    try {
      await authStore.fetchUser()
    } catch (error) {
      console.error('Error fetching user in middleware:', error)
      authStore.clearAuthData()
      return navigateTo('/login')
    }
  }

  // Verificação de permissões baseada em meta
  if (to.meta.requiresPermission && !authStore.hasPermission(to.meta.requiresPermission)) {
    return navigateTo('/unauthorized')
  }

  // Verificação de roles baseada em meta
  if (to.meta.requiresRole && !authStore.hasRole(to.meta.requiresRole)) {
    return navigateTo('/unauthorized')
  }
})